.App {
  text-align: center;
}
body{
  background-color: #d8d8d8;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: 'Blank River';
  src: local('Blank River'), url('./fonts/Blank River.ttf') format('truetype');
}
@font-face {
  font-family: 'Bebas Neue Pro Regular';
  src: local('Bebas Neue Pro Regular'), url('./fonts/Bebas Neue Pro Regular.otf') format('truetype');
}
@font-face {
  font-family: 'Bebas Neue Pro Bold';
  src: local('Bebas Neue Pro Bold'), url('./fonts/Bebas Neue Pro Bold.otf') format('truetype');
}
@font-face {
  font-family: 'Bebas Neue Pro Light';
  src: local('Bebas Neue Pro Light'), url('./fonts/Bebas Neue Pro Light.otf') format('truetype');
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
